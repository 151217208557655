import revive_payload_client_CzOWEgVCps from "/codebuild/output/src3505197098/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.13_db0@0.3.1_eslint@8.57.1_ioredis@_42664d3c4eaef44ecdefb447ac21f70d/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_pJGgjlpiMB from "/codebuild/output/src3505197098/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.13_db0@0.3.1_eslint@8.57.1_ioredis@_42664d3c4eaef44ecdefb447ac21f70d/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_tv2n39zRWJ from "/codebuild/output/src3505197098/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.13_db0@0.3.1_eslint@8.57.1_ioredis@_42664d3c4eaef44ecdefb447ac21f70d/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_6m9Pfk3pLZ from "/codebuild/output/src3505197098/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt-site-config@2.2.21_magicast@0.3.5_vite@6.2.3_@types+node@22.13.13_jiti@2.4.2_light_2ae156c01302749285059751d0e18839/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_0mYrv5N0UY from "/codebuild/output/src3505197098/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.13_db0@0.3.1_eslint@8.57.1_ioredis@_42664d3c4eaef44ecdefb447ac21f70d/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_wdPE3MX3So from "/codebuild/output/src3505197098/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.13_db0@0.3.1_eslint@8.57.1_ioredis@_42664d3c4eaef44ecdefb447ac21f70d/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_3rWnOEinfi from "/codebuild/output/src3505197098/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.13_db0@0.3.1_eslint@8.57.1_ioredis@_42664d3c4eaef44ecdefb447ac21f70d/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_T9Yc1n6bVj from "/codebuild/output/src3505197098/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.13_db0@0.3.1_eslint@8.57.1_ioredis@_42664d3c4eaef44ecdefb447ac21f70d/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_mpTpts533Z from "/codebuild/output/src3505197098/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_typescript@5.8.2_vue@3.5.13_typescript@5.8.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/codebuild/output/src3505197098/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/.nuxt/components.plugin.mjs";
import prefetch_client_8EfdgOHmL4 from "/codebuild/output/src3505197098/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.13_db0@0.3.1_eslint@8.57.1_ioredis@_42664d3c4eaef44ecdefb447ac21f70d/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_OHU0vggoM9 from "/codebuild/output/src3505197098/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_aicRBgujyl from "/codebuild/output/src3505197098/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_magicast@0.3.5_nuxt@3.15.4_@parcel+watcher@2.5.1_@type_9c30057f22257107fbb60a94c40b9462/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_9URT5uewBa from "/codebuild/output/src3505197098/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@nuxt+icon@1.11.0_magicast@0.3.5_vite@6.2.3_@types+node@22.13.13_jiti@2.4.2_lightningcs_bf41371cb8411ea6137dcac0a4efb2c4/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_VnlTItYkHc from "/codebuild/output/src3505197098/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@storyblok+nuxt@6.2.4_vue@3.5.13_typescript@5.8.2_/node_modules/@storyblok/nuxt/dist/runtime/plugin.js";
import plugin_PYjyu3Xzan from "/codebuild/output/src3505197098/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/pinia-plugin-persistedstate@4.2.0_@pinia+nuxt@0.5.5_magicast@0.3.5_typescript@5.8.2_vue_6cc402edc64b5b431717c79808b7ec48/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import switch_locale_path_ssr_EBPFPAzp2R from "/codebuild/output/src3505197098/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.37.0_vue@3.5.13_typescript@5.8.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_XuAl0L7syr from "/codebuild/output/src3505197098/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.37.0_vue@3.5.13_typescript@5.8.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import base64_2hii42QBJd from "/codebuild/output/src3505197098/src/bitbucket.org/magusa/cavu-workspaces/apps/_base/plugins/base64.ts";
export default [
  revive_payload_client_CzOWEgVCps,
  unhead_pJGgjlpiMB,
  router_tv2n39zRWJ,
  _0_siteConfig_6m9Pfk3pLZ,
  payload_client_0mYrv5N0UY,
  navigation_repaint_client_wdPE3MX3So,
  check_outdated_build_client_3rWnOEinfi,
  chunk_reload_client_T9Yc1n6bVj,
  plugin_vue3_mpTpts533Z,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8EfdgOHmL4,
  plugin_OHU0vggoM9,
  plugin_aicRBgujyl,
  plugin_9URT5uewBa,
  plugin_VnlTItYkHc,
  plugin_PYjyu3Xzan,
  switch_locale_path_ssr_EBPFPAzp2R,
  i18n_XuAl0L7syr,
  base64_2hii42QBJd
]